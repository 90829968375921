// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Details-module__wrapperDisplay___u4FgS{display:grid;grid-gap:20px;gap:20px}.Details-module__containerColItem____3bNS{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:5px}.Details-module__containerColItemValue___Oe2lX{font-size:16px}.Details-module__containerColItemTitle___euwmW{font-size:14px}.Details-module__wrapperCols___P58Fn{display:-webkit-box;display:-ms-flexbox;display:flex;width:50%;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row wrap;flex-flow:row wrap}@media(max-width: 767px){.Details-module__wrapperCols___P58Fn{width:100%}}", "",{"version":3,"sources":["webpack://./src/pages/Sphere/pages/containers/HeroDetails/components/Details/Details.module.scss"],"names":[],"mappings":"AACA,wCACE,YAAA,CACA,aAAA,CAAA,QAAA,CAGF,0CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,OAAA,CAEA,+CACE,cAAA,CAGF,+CACE,cAAA,CAIJ,qCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,SAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CAEA,yBALF,qCAMI,UAAA,CAAA","sourcesContent":["\n.wrapperDisplay {\n  display: grid;\n  gap: 20px;\n}\n\n.containerColItem {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n\n  &Value {\n    font-size: 16px;\n  }\n\n  &Title {\n    font-size: 14px;\n  }\n}\n\n.wrapperCols {\n  display: flex;\n  width: 50%;\n  flex-flow: row wrap;\n\n  @media (width < 768px) {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperDisplay": "Details-module__wrapperDisplay___u4FgS",
	"containerColItem": "Details-module__containerColItem____3bNS",
	"containerColItemValue": "Details-module__containerColItemValue___Oe2lX",
	"containerColItemTitle": "Details-module__containerColItemTitle___euwmW",
	"wrapperCols": "Details-module__wrapperCols___P58Fn"
};
export default ___CSS_LOADER_EXPORT___;
