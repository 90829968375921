// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mapValidatorTable-module__idStatus___ysNew{font-size:14px;color:#fff}.mapValidatorTable-module__idStatus___ysNew.mapValidatorTable-module__imperator___sCvHR,.mapValidatorTable-module__idStatus___ysNew.mapValidatorTable-module__jedi___MSFlu{color:red}.mapValidatorTable-module__idStatus___ysNew.mapValidatorTable-module__padawan___i8HLO{color:#ffca42}", "",{"version":3,"sources":["webpack://./src/pages/Sphere/pages/containers/Heroes/components/ValidatorTable/utils/mapValidatorTable.module.scss"],"names":[],"mappings":"AAAA,4CACE,cAAA,CACA,UAAA,CAEA,2KAEE,SAAA,CAGF,sFACE,aAAA","sourcesContent":[".idStatus {\n  font-size: 14px;\n  color: #fff;\n\n  &.imperator,\n  &.jedi {\n    color: #ff0000;\n  }\n\n  &.padawan {\n    color: #ffca42;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"idStatus": "mapValidatorTable-module__idStatus___ysNew",
	"imperator": "mapValidatorTable-module__imperator___sCvHR",
	"jedi": "mapValidatorTable-module__jedi___MSFlu",
	"padawan": "mapValidatorTable-module__padawan___i8HLO"
};
export default ___CSS_LOADER_EXPORT___;
