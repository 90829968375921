// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VotingPower-module__container___i4DkO{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;gap:5px}.VotingPower-module__container___i4DkO .VotingPower-module__tokens___YeVYg{font-size:10px;color:var(--grayscale-secondary)}", "",{"version":3,"sources":["webpack://./src/pages/Sphere/pages/containers/Heroes/components/ValidatorTable/ui/VotingPower/VotingPower.module.scss"],"names":[],"mappings":"AAAA,uCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,qBAAA,CAAA,kBAAA,CAAA,oBAAA,CACA,OAAA,CAEA,2EACE,cAAA,CACA,gCAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 5px;\n\n  .tokens {\n    font-size: 10px;\n    color: var(--grayscale-secondary);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VotingPower-module__container___i4DkO",
	"tokens": "VotingPower-module__tokens___YeVYg"
};
export default ___CSS_LOADER_EXPORT___;
