// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Delegate-module__containerText___mXX6a{display:-webkit-box;display:-ms-flexbox;display:flex;gap:5px}.Delegate-module__moniker___byfPK{font-size:20px;text-transform:none}", "",{"version":3,"sources":["webpack://./src/pages/Sphere/pages/components/ActionBarContainer/components/Delegate/Delegate.module.scss"],"names":[],"mappings":"AAAA,wCACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,OAAA,CAGF,kCACE,cAAA,CACA,mBAAA","sourcesContent":[".containerText {\n  display: flex;\n  gap: 5px;\n}\n\n.moniker {\n  font-size: 20px;\n  text-transform: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerText": "Delegate-module__containerText___mXX6a",
	"moniker": "Delegate-module__moniker___byfPK"
};
export default ___CSS_LOADER_EXPORT___;
