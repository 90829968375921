// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArrowToggle-module__btnOpenIconArrowImg___Ff_8h{width:14px;height:14px;-o-object-fit:contain;object-fit:contain;-webkit-transition:.5s;transition:.5s}.ArrowToggle-module__btnOpenIconArrowImgOpen___rcWYn{-webkit-transform:rotate(90deg);transform:rotate(90deg)}", "",{"version":3,"sources":["webpack://./src/components/ArrowToggle/ArrowToggle.module.scss"],"names":[],"mappings":"AAAA,iDACE,UAAA,CACA,WAAA,CACA,qBAAA,CAAA,kBAAA,CACA,sBAAA,CAAA,cAAA,CAEA,qDACE,+BAAA,CAAA,uBAAA","sourcesContent":[".btnOpenIconArrowImg {\n  width: 14px;\n  height: 14px;\n  object-fit: contain;\n  transition: 0.5s;\n\n  &Open {\n    transform: rotate(90deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnOpenIconArrowImg": "ArrowToggle-module__btnOpenIconArrowImg___Ff_8h",
	"btnOpenIconArrowImgOpen": "ArrowToggle-module__btnOpenIconArrowImgOpen___rcWYn"
};
export default ___CSS_LOADER_EXPORT___;
