// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Statistics-module__wrapper___v92Q3{display:grid;grid-template-columns:repeat(auto-fit, minmax(220px, 1fr));margin-top:10px;margin-bottom:50px;grid-gap:20px;gap:20px}.Statistics-module__identityContainer___epaRA{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;gap:10px}.Statistics-module__monikerContainer___UDQJp{display:-webkit-box;display:-ms-flexbox;display:flex;gap:10px}", "",{"version":3,"sources":["webpack://./src/pages/Sphere/pages/containers/HeroDetails/components/Statistics/Statistics.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,0DAAA,CACA,eAAA,CACA,kBAAA,CACA,aAAA,CAAA,QAAA,CAGF,8CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,QAAA,CAIF,6CACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA","sourcesContent":[".wrapper {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));\n  margin-top: 10px;\n  margin-bottom: 50px;\n  gap: 20px;\n}\n\n.identityContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n\n}\n\n.monikerContainer {\n  display: flex;\n  gap: 10px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Statistics-module__wrapper___v92Q3",
	"identityContainer": "Statistics-module__identityContainer___epaRA",
	"monikerContainer": "Statistics-module__monikerContainer___UDQJp"
};
export default ___CSS_LOADER_EXPORT___;
