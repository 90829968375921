// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoBalance-module__containerGrid___XnQbB{display:grid;grid-template-columns:repeat(auto-fit, minmax(200px, 1fr));grid-gap:20px;gap:20px;margin-top:10px;margin-bottom:50px}", "",{"version":3,"sources":["webpack://./src/pages/Sphere/pages/containers/Heroes/components/InfoBalance/InfoBalance.module.scss"],"names":[],"mappings":"AAAA,2CACE,YAAA,CACA,0DAAA,CACA,aAAA,CAAA,QAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".containerGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 20px;\n  margin-top: 10px;\n  margin-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerGrid": "InfoBalance-module__containerGrid___XnQbB"
};
export default ___CSS_LOADER_EXPORT___;
