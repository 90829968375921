// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Moniker-module__monikerContainer___WFY8n{width:200px;display:-webkit-box;display:-ms-flexbox;display:flex;gap:20px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative}.Moniker-module__monikerContainer___WFY8n>img{width:30px !important;height:30px !important}.Moniker-module__monikerContainer___WFY8n .Moniker-module__moniker___FI8PH{text-transform:none !important}.Moniker-module__monikerContainer___WFY8n .Moniker-module__moniker___FI8PH{text-align:left;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;padding-right:5px}", "",{"version":3,"sources":["webpack://./src/pages/Sphere/pages/containers/Heroes/components/ValidatorTable/ui/Moniker/Moniker.module.scss"],"names":[],"mappings":"AAAA,0CACE,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,QAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,iBAAA,CAEA,8CACE,qBAAA,CACA,sBAAA,CAGF,2EAKE,8BACA,CANF,2EACE,eAAA,CACA,sBAAA,CACA,eAAA,CACA,kBAAA,CAEA,iBAAA","sourcesContent":[".monikerContainer {\n  width: 200px;\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  position: relative;\n\n  > img {\n    width: 30px !important;\n    height: 30px !important;\n  }\n\n  .moniker {\n    text-align: start;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    text-transform: none !important;\n    padding-right: 5px;\n    // color: #36d6ae;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"monikerContainer": "Moniker-module__monikerContainer___WFY8n",
	"moniker": "Moniker-module__moniker___FI8PH"
};
export default ___CSS_LOADER_EXPORT___;
